import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import blogTemplateStyles from "../styles/templates/blog.module.scss"
import FeatherIcon from "feather-icons-react"
import layoutStyles from "../styles/components/layout.module.scss"

export default function Blog(props) {
  const data = props.data.markdownRemark
  const allBlogData = useBlogData()
  const nextSlug = getNextSlug(data.fields.slug)
  const prevSlug = getPrevSlug(data.fields.slug)

  function getNextSlug(slug) {
    const allSlugs = allBlogData
      .filter(
        blog =>
          blog.node.frontmatter.title !== "" &&
          blog.node.frontmatter.hero_image !== null
      )
      .map((blog, idx) => {
        return blog.node.fields.slug
      })
    const nextSlug = allSlugs[allSlugs.indexOf(slug) + 1]
    if (nextSlug !== undefined && nextSlug !== "") {
      return nextSlug
    } else {
      return allSlugs[0]
    }
  }

  function getPrevSlug(slug) {
    const allSlugs = allBlogData
      .filter(
        blog =>
          blog.node.frontmatter.title !== "" &&
          blog.node.frontmatter.hero_image !== null
      )
      .map((blog, idx) => {
        return blog.node.fields.slug
      })
    const prevSlug = allSlugs[allSlugs.indexOf(slug) - 1]
    if (prevSlug !== undefined && prevSlug !== "") {
      return prevSlug
    } else {
      return allSlugs[0]
    }
  }

  return (
    <Layout>
      {data.frontmatter.hero_image != null && (
        <article className={blogTemplateStyles.blog}>
          <div className={blogTemplateStyles.static_info}>
            <div className={blogTemplateStyles.blog_header}>
              <Link to="/" className={blogTemplateStyles.back_btn}>
                <FeatherIcon
                  icon="arrow-left"
                  size="34"
                  color="#000"
                  strokeWidth="2"
                />
              </Link>
              <div className={blogTemplateStyles.prev_next__btns}>
                {prevSlug !== "site-logo" && (
                  <Link
                    to={`/blog/${prevSlug}`}
                    className={blogTemplateStyles.footer__next}
                  >
                    <FeatherIcon
                      icon="arrow-left"
                      size="22"
                      color="#fff"
                      strokeWidth="1"
                    />
                  </Link>
                )}
                <p>/</p>
                {nextSlug !== "site-logo" && (
                  <Link
                    to={`/blog/${nextSlug}`}
                    className={blogTemplateStyles.footer__next}
                  >
                    <FeatherIcon
                      icon="arrow-right"
                      size="22"
                      color="#fff"
                      strokeWidth="1"
                    />
                  </Link>
                )}
              </div>
            </div>
            <div
              className={blogTemplateStyles.blog__body}
              dangerouslySetInnerHTML={{ __html: data.html }}
            ></div>
            <h2>Used skills:</h2>
            <ul className={blogTemplateStyles.pills}>
              {data.frontmatter.used_skills.map((skill, idx) => (
                <li key={idx}>{skill}</li>
              ))}
            </ul>

            <div className={blogTemplateStyles.blog__footer}>
              {data.frontmatter.url !== null  && (
                  <a
                    href={data.frontmatter.url}
                    target="_blank"
                    rel="noreferrer"
                    className={layoutStyles.main_btn}
                  >
                    Visit
                    <FeatherIcon
                      icon="arrow-right"
                      size="22"
                      color="#000"
                      strokeWidth="1"
                    />
                  </a>
                )}
            </div>
          </div>
          <div>
            <img
              alt="website Ashley Van Laer"
              src={data.frontmatter.home_image.childImageSharp.fluid.src}
            />
            {data.frontmatter.image_2 != null && (
              <img
                alt="website Ashley Van Laer"
                src={data.frontmatter.image_2.childImageSharp.fluid.src}
              />
            )}
            {data.frontmatter.image_3 != null && (
              <img
                alt="website Ashley Van Laer"
                src={data.frontmatter.image_3.childImageSharp.fluid.src}
              />
            )}
            {data.frontmatter.image_4 != null && (
              <img
                alt="website Ashley Van Laer"
                src={data.frontmatter.image_4.childImageSharp.fluid.src}
              />
            )}
          </div>
        </article>
      )}
    </Layout>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        used_skills
        url
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        home_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_2 {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_3 {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_4 {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
